<script lang="ts" setup>
import CustomModalWindow from '~/components/modal-windows/CustomModalWindow.vue'

const model = defineModel<boolean>()

const props = withDefaults(
  defineProps<{
    isPrice?: boolean
  }>(),
  { isPrice: true }
)

function close (): void {
  model.value = false
}
</script>

<template>
  <CustomModalWindow v-model="model">
    <template #title>
      {{ isPrice ? 'Цена' : 'Сумма' }} с&nbsp;промокодом
    </template>

    <p>Воспользуйтесь промокодом и&nbsp;получите скидку!</p>
    <p>Указанная {{ isPrice ? 'цена' : 'сумма' }} действительна только при применении партнёрского промокода.</p>

    <template #action>
      <a href="#" @click.prevent="close">Закрыть</a>
    </template>
  </CustomModalWindow>
</template>
